$(function(){
    $('.js-menu-hover').bind({
        'mouseenter' : function(){
            $(this).find('.parent').addClass('open');
            $(this).find('.js-menu-children').addClass('open');
        },
        'mouseleave' : function(){
            $(this).find('.parent').removeClass('open');
            $(this).find('.js-menu-children').removeClass('open');
        }
    })
});