$(function(){
    changeFontSize(GetCookie("fontSize"))

    $('.js-fontSize').on('click', function(){
        
        let selectSize = $(this).attr('data-size')
        changeFontSize(selectSize)
    })
    
    //文字サイズ変更
    function changeFontSize(value){
        
        switch (value) {
            case 'small':
                defaultSize = 16
                break;
            // case 'medium':
            //     defaultSize = 14
            //     break;
            case 'large':
                defaultSize = 20
                break;
            default:
                defaultSize = 16
                value = 'small'
        }
        SetCookie('fontSize',value);
        $('.js-fontSize').removeClass('on')
        $('.js-fontSize[data-size="' + value + '"]').addClass('on')
        $('html').css({
            'font-size' : defaultSize
        })
    }

    // Cookieの読み込み * keyの引数で値を取得
    function GetCookie(key) {
        key = "@" + key;
        get_data = document.cookie;
        cv = new Array();
        gd = get_data.split(";");
        for (i in gd) {
           a = gd[i].split("=");
           a[0] = a[0].replace(" ","");
           cv[a[0]] = a[1];
        }
        if (cv[key]) return cv[key];
        else return "";
    }
    
    // Cookieの書き出し
    function SetCookie(key,val) {
        //有効期限の設定
        days = 30;	//30日先まで
        ex = new Date();
        ex = new Date(ex.getTime() + (1000 * 60 * 60 * 24 * days));
        y = ex.getYear(); if (y < 1900) y += 1900;
        hms = ex.getHours() + ":" + ex.getMinutes() + ":" + ex.getSeconds();
        p = String(ex).split(" ");
        ex = p[0] + ", " + p[2] + "-" + p[1] + "-" + y + " " + hms + " GMT;";
        //keyに@を付けて書き出し
        document.cookie = "@" + key + "=" + val +"; path=/; expires=" + ex;
    }
})
