$(window).on('scroll', function(){
    var sTop = $(window).scrollTop();
    
    if(sTop > 300){
        $('.js-toTop').addClass('show');
    }else{
        $('.js-toTop').removeClass('show');
    }
});

$('.js-toTop').on('click', function(){
    $('html,body').animate({ scrollTop: 0 }, 1000);
})